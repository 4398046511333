<template>
  <div>
    <ManageForm4 />
  </div>
</template>
<script>
import ManageForm4 from "@/components/ManageForm4/ManageForm4";
export default {
  components: {
    ManageForm4,
  },
  created() { this.year = JSON.parse(
        Decode.decode(localStorage.getItem("yearSelected"))
      );
  },
};
</script>